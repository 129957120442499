    @font-face{
        font-family:"Avenir";
        font-weight:300;
        src:url("fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
        src:url("fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype");
    }
    @font-face{
        font-family:"Avenir";
        font-weight:400;
        src:url("fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix");
        src:url("fonts/710789a0-1557-48a1-8cec-03d52d663d74.eot?#iefix") format("eot"),url("fonts/065a6b14-b2cc-446e-9428-271c570df0d9.woff2") format("woff2"),url("fonts/65d75eb0-2601-4da5-a9a4-9ee67a470a59.woff") format("woff"),url("fonts/c70e90bc-3c94-41dc-bf14-caa727c76301.ttf") format("truetype");
    }
    @font-face{
        font-family:"Avenir";
        font-weight:700;
        src:url("fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix");
        src:url("fonts/e0542193-b2e6-4273-bc45-39d4dfd3c15b.eot?#iefix") format("eot"),url("fonts/17b90ef5-b63f-457b-a981-503bb7afe3c0.woff2") format("woff2"),url("fonts/c9aeeabd-dd65-491d-b4be-3e0db9ae47a0.woff") format("woff"),url("fonts/25f994de-d13f-4a5d-a82b-bf925a1e054d.ttf") format("truetype");
    }
    @font-face{
        font-family:"Avenir";
        font-weight:900;
        src:url("fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix");
        src:url("fonts/f55e4498-ad48-4577-93a0-c614de5cbbb8.eot?#iefix") format("eot"),url("fonts/c78eb7af-a1c8-4892-974b-52379646fef4.woff2") format("woff2"),url("fonts/75b36c58-2a02-4057-a537-09af0832ae46.woff") format("woff"),url("fonts/b5c44a82-eeb8-41de-9c3f-a8db50b24b8a.ttf") format("truetype");
    }
    @font-face{
        font-family:"Cookie";
        src:url("fonts/cookie-v9-latin-regular.woff2") format("woff2"),url("fonts/cookie-v9-latin-regular.woff") format("woff");
    }
